import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "The Why of The Purepost Skills Translation Method",
  "author": "Purepost",
  "date": "2018-12-03T18:00:52.000Z",
  "categories": ["Military Transition"],
  "slug": "purepost-skills-translation-method",
  "draft": false,
  "meta_title": "The Why of The Purepost Skills Translation Method",
  "cover": "../../images/wp_blog_images/Screen-Shot-2018-12-03-at-2.14.47-PM.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`We've Walked in Your Boots`}</strong></p>
    <p>{`Have you ever been to a Military resume builder or translation website and the system tells you that your Military position doesn’t transfer? We’ve seen it and we’ve experienced it. That feeling you had of “what do you mean?”, is understood and felt here at Purepost.`}</p>
    <p>{`My father Tony Garcia, Sr. and I are Veterans. Tony experienced the Military transition challenge in 1993, when he retired as a LTC. I did later in 2007.`}</p>
    <p>{`We got fed up with the current "bad solutions" and decided to make something that works for all Veterans and Service Members.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB6ElEQVQoz01S2Y7bMAz0/39Bv6UP26bAFlg0l+tN2qYLJMjRxLFjS5YU27qmIO1sS2AAUiJHQ4pJmq6RZT+xmGeYTF6Qpj+wWm2QLldYLDLM5xlms++YTpeYfhswmw7geLrE1+cXrqf8RGsD7z1C8CDbvW2w3fxC13cQooaUAtb2+N/aNsLoAO/D+xnVxxiQNE0DQt8PRZ+eJph8fkbfd7heryiKElVVQSmNrus4z7nA8D5CVEcYXXJMDzOhMQbOOVhrERGZuLpJVJXgx7TW7wiBVMURwJenD1i/fmSfRCRSSk58EFrruBU7qnA+8B1hGE1gkO+chzESbWvYp/qEyO73+1DkPbQSUKJAZwRc1yC64a5RilunXGo9xsgzIwG2d3DWcl7Sti0IxE7KdH1Guc/QVlv45oCgTvDOQjYNrnmO2+0G6opGIYTA5XKBMZoVs8JHO0wYIpryiHy7wL18Q5BbhF4hhIiiKLDb7bDf73E8HpHnOZ+VZcmCHhzJMDc7EgY0txPy7RKm+A2v/iCEYabU7vl8Rl3XXExGrVNMn0r1tAGDwpGQ/O6uYGQJIwv0rWZ11A4Rnk4nXiUioZjUUUyED0v+/e5I2PWslJYixMg/Sj9IszscDkxKRDS/YT8VP5ilM6xfl/gLk1pHwezwtr4AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Screen Shot 2018 12 03 at 2 14 47 PM",
        "title": "Screen Shot 2018 12 03 at 2 14 47 PM",
        "src": "/static/b19606bc0d3f7dd01fa7430303675223/21b4d/Screen-Shot-2018-12-03-at-2.14.47-PM.png",
        "srcSet": ["/static/b19606bc0d3f7dd01fa7430303675223/72799/Screen-Shot-2018-12-03-at-2.14.47-PM.png 320w", "/static/b19606bc0d3f7dd01fa7430303675223/6af66/Screen-Shot-2018-12-03-at-2.14.47-PM.png 640w", "/static/b19606bc0d3f7dd01fa7430303675223/21b4d/Screen-Shot-2018-12-03-at-2.14.47-PM.png 1280w", "/static/b19606bc0d3f7dd01fa7430303675223/29114/Screen-Shot-2018-12-03-at-2.14.47-PM.png 1920w", "/static/b19606bc0d3f7dd01fa7430303675223/c2d13/Screen-Shot-2018-12-03-at-2.14.47-PM.png 2560w", "/static/b19606bc0d3f7dd01fa7430303675223/d9ed5/Screen-Shot-2018-12-03-at-2.14.47-PM.png 2880w"],
        "sizes": "(max-width: 1280px) 100vw, 1280px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Unlike many resume builders and websites that know little more than military titles and ranks, Purepost knows “how the military runs” and more importantly - deeply understands the amazing takeaways from your service experience. We illustrate this in your Purepost profile and resume, which takes less than 10 minutes to create.`}</p>
    <p>{`We know the complexity of each military speciality and we work hard to fully and accurately understand where you’ve been and what you have done in your specific specialty.`}</p>
    <p>{`Purepost can do this because we possess a rich and clear understanding of your military career path, training, education, and position responsibilities that correspond directly to your career experience.`}</p>
    <p>{`Purepost also possesses a real time inventory of job postings to make sure we can match your real capabilities to your future civilian job aspirations.`}</p>
    <p><strong parentName="p">{`How We Create an Effective Civilian Resume`}</strong></p>
    <ol>
      <li parentName="ol">{`We take a hard look at your military position experience by examining each position held against the official regulation based responsibilities and duty bullets`}</li>
      <li parentName="ol">{`We research and determine the skills you acquired during your military career path, that includes broadening assignments, training and real world problem solving.`}</li>
      <li parentName="ol">{`We relate your total experience, training, and final position level attained to a compatible civilian industry job position and role`}</li>
      <li parentName="ol">{`Purepost develops civilian compatible resume statements to compliment your military duties by position`}</li>
      <li parentName="ol">{`With each resume statement, we pair up civilian leadership and technical skills that reflect what you bring to the civilian job market.`}</li>
    </ol>
    <p><strong parentName="p">{`Why Your Military Job Title and Description is Translated`}</strong></p>
    <p>{`As a Military member, your rank and duty title/position may not make sense to a corporate recruiter. Your Purepost profile and resume bridges the gap between Military and civilian life as you transition. Your civilian compatible job title and resume statements may not be clear to you at first, because we are focusing on descriptions of what you accomplished with the mindset of a civilian recruiter/hiring manager. If the civilian recruiter or hiring manager don't understand what you did - you wont get an interview. You’ll see differences in what you may have created in the past and what your Purepost resume states. We match a civilian job title with your Military duty title to support your job responsibilities and level within the Army. That civilian job title is listed on your resume to bridge the gap.`}</p>
    <p><strong parentName="p">{`Your Skills`}</strong></p>
    <p>{`At the top of your Purepost profile and  resume, you will see your top nine civilian skills. Purepost uses its research based, proprietary Skills Bank and mapping algorithm to accurately map in-demand skills that you have acquired during your military service to your resume statements. Review your resume statements and compare them with the skills listed. Make sure they make sense to you. Then review your top skills and research what they mean and how they relate to what you did in the Military.`}</p>
    <p><strong parentName="p">{`We're Here to Help!`}</strong></p>
    <p>{`We know this transition process is “the road less traveled” for you, your buddies, and even your families. The job search process brings uncertainty and fear to many people. Consider us your battle buddy in your search for a new job. We are here to provide support to veterans along this journey.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      